import { useState } from "react"

import { MountingPlateOrderConfirmationStep } from "src/components/order/MountingPlateStore/MountingPlateOrderConfirmationStep"
import {
  getPlateStorePostBody,
  SHIPPING_ADDRESS_FORM_ID,
} from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/mountingPlateOrderUtil"
import { PaymentMethodStep } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/PaymentMethodStep"
import { PlateStoreSuccessDialog } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/PlateStoreSuccessDialog"
import { ShippingAddressStep } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/ShippingAddressStep"
import { IShippingAddress } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/shippingAddressTypes"
import { ShippingMethodStep } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/ShippingMethodStep"
import { useMountingPlateOrderContext } from "src/components/order/MountingPlateStore/useMountingPlateOrderContext"
import { useFetchPaymentMethod } from "src/data/billing/queries/billingQueries"
import { TUserBillingInfoBillingAddress } from "src/data/billing/types/billingTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import {
  IVerticalWizardStep,
  VerticalWizard,
} from "src/ui/VerticalWizard/VerticalWizard"

import { useMountingPlateOrder } from "./useMountingPlateOrder"

export function MountingPlateOrderWizard() {
  const { t, langKeys } = useTranslate()
  const {
    setShippingAddress,
    storeShippingAddress,
    shippingAddress,
    userBillingInfo,
    nbrPlates,
    totalCost,
    formattedCost,
    currencyCode,
  } = useMountingPlateOrderContext()

  const fetchPaymentMethod = useFetchPaymentMethod({
    paymentSourceId: userBillingInfo?.primary_payment_source_id,
    options: {
      enabled: !!userBillingInfo?.primary_payment_source_id,
    },
  })
  const card = fetchPaymentMethod.data?.card

  const [currentStep, setCurrentStep] = useState(0)

  const [shippingMethodComplete, setShippingMethodComplete] = useState(false)
  const [paymentMethodComplete, setPaymentMethodComplete] = useState(false)

  const [showOrderSuccess, setShowOrderSuccess] = useState(false)
  const {
    plateOrderError,
    handleSubmitPlateOrder,
    isLoading: plateOrderLoading,
  } = useMountingPlateOrder({
    onSuccess: () => setShowOrderSuccess(true),
  })

  const steps: IVerticalWizardStep[] = [
    {
      title: t(langKeys.shipping_address),
      preview: (
        <MText variant="subtitleS">
          <AddressPreview {...shippingAddress} />
        </MText>
      ),
      completed: !!shippingAddress,
      component: (
        <ShippingAddressStep
          key={JSON.stringify(userBillingInfo?.billing_address)}
          formId={SHIPPING_ADDRESS_FORM_ID}
          onSubmit={(submittedShippingAddress) => {
            setCurrentStep((n) => n + 1)
            setShippingAddress(submittedShippingAddress)
            // Store data in session storage in case the user comes back to this
            // page:
            storeShippingAddress()
          }}
        />
      ),
      nextButtonProps: { form: SHIPPING_ADDRESS_FORM_ID },
    },
    {
      title: t(langKeys.shipping_method),
      preview: (
        <MText variant="subtitleS">
          {t(langKeys.standard)} ({t(langKeys.free)})
        </MText>
      ),
      completed: shippingMethodComplete,
      component: <ShippingMethodStep />,
      onNext: () => {
        setCurrentStep((n) => n + 1)
        setShippingMethodComplete(true)
      },
    },
    {
      title: t(langKeys.payment_method),
      preview: (
        <MText variant="subtitleS">
          <PaymentMethodPreview
            cardEndDigits={card?.last4}
            billingAddress={userBillingInfo?.billing_address}
          />
        </MText>
      ),
      completed: paymentMethodComplete,
      component: (
        <PaymentMethodStep
          cardEndDigits={card?.last4}
          billingAddress={userBillingInfo?.billing_address}
          vatNumber={userBillingInfo?.vat_number}
        />
      ),
      onNext: () => {
        setCurrentStep((n) => n + 1)
        setPaymentMethodComplete(true)
      },
      nextButtonProps: { disabled: !card?.last4 },
    },
    {
      title: t(langKeys.confirm_place_order),
      completed: false,
      component: (
        <MountingPlateOrderConfirmationStep
          nbrPlates={nbrPlates}
          formattedCost={formattedCost}
          error={plateOrderError}
        />
      ),
      nextButtonProps: {
        fullWidth: true,
        disabled: !card,
        loading: plateOrderLoading,
      },
      nextButtonLabel: t(langKeys.place_order),
      onNext: () => {
        const body = getPlateStorePostBody({
          shippingAddress,
          nbrPlates,
          totalCost,
          currencyCode,
        })
        if (!body) {
          throw Error("Attempted submitting incomplete shipping address")
        }
        handleSubmitPlateOrder(body)
      },
    },
  ]

  return (
    <div>
      <VerticalWizard
        steps={steps}
        currentStep={currentStep}
        onNext={() => setCurrentStep((n) => n + 1)}
        onEdit={(n) => setCurrentStep(n)}
      />

      <PlateStoreSuccessDialog open={showOrderSuccess} />
    </div>
  )
}

function AddressPreview(
  props: IShippingAddress | TUserBillingInfoBillingAddress | undefined
) {
  if (!props) return null
  const fullName = (
    <span>
      {props.first_name} {props.last_name}
    </span>
  )

  const street = props.line2 ? (
    <span>
      {props.line1} {props.line2}
    </span>
  ) : (
    <span>{props.line1}</span>
  )

  const stateCountry = props.state_code ? (
    <span>
      {props.state_code}, {props.country}
    </span>
  ) : (
    <span>{props.country}</span>
  )

  return (
    <span>
      {fullName}, {street}, {props.city}, {props.zip}, {stateCountry}
    </span>
  )
}

function PaymentMethodPreview({
  billingAddress,
  cardEndDigits,
}: {
  billingAddress: TUserBillingInfoBillingAddress | undefined
  cardEndDigits: string | undefined
}) {
  const { t, langKeys } = useTranslate()
  if (!billingAddress) {
    return null
  }

  return (
    <div>
      <span hidden={!cardEndDigits}>
        {t(langKeys.card_ending_in, { digits: cardEndDigits })},{" "}
      </span>
      <AddressPreview {...billingAddress} />
    </div>
  )
}
