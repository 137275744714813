import { isEuropeanUnionMemberState } from "src/components/Account/BillingPortal/countryData"
import {
  IShippingAddress,
  TShippingregions,
} from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/shippingAddressTypes"
import {
  TSubscriptionCurrencyCode,
  TUserBillingInfoBillingAddress,
} from "src/data/billing/types/billingTypes"
import { TCountryDataCodes } from "src/data/countries/countryTypes"
import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { TPlateStorePostBody } from "src/data/plateStore/types/plateStoreTypes"
import { Maybe } from "src/utils/tsUtil"

export const SHIPPING_ADDRESS_FORM_ID = "shipping address form id"

export function extractShippingAddressData({
  city,
  company,
  country,
  first_name,
  last_name,
  line1,
  line2,
  phone,
  state_code,
  zip,
}: TUserBillingInfoBillingAddress): IShippingAddress {
  return {
    city,

    company,
    country: country as IShippingAddress["country"],
    first_name,
    last_name,
    line1,
    line2,
    phone,
    state_code,
    zip,
  }
}

export function getPlateShippingRegion(
  countryCode: TCountryDataCodes
): TShippingregions {
  switch (countryCode) {
    case "SE":
    case "GB":
    case "US":
    case "CA":
    case "AU":
    case "NZ":
      return countryCode

    default:
      return isEuropeanUnionMemberState(countryCode) ? "EU" : "ROW"
  }
}

/** Cost for plates + shipping
 *
 * https://docs.google.com/spreadsheets/d/1VEH0Jfq1jqdIhHOvm34u_JSngS-HLHZw2ANFgq-6kzM/edit#gid=813187974
 */
export function getPlateUnitCost(region: TShippingregions): number {
  switch (region) {
    case "SE":
      return 200

    case "EU":
      return 35

    case "GB":
      return 40

    case "US":
      return 55

    case "CA":
      return 75

    case "AU":
    case "NZ":
      return 100

    default:
      return 65
  }
}

export function getCurrencyFromShippingRegion(
  region: TShippingregions
): TSubscriptionCurrencyCode {
  switch (region) {
    case "SE":
      return "SEK"
    case "EU":
      return "EUR"
    case "GB":
      return "GBP"
    case "US":
      return "USD"
    case "CA":
      return "CAD"
    case "AU":
    case "NZ":
      return "AUD"
    default: // Rest Of World
      return "USD"
  }
}

export function getPlateStorePostBody({
  shippingAddress,
  nbrPlates,
  totalCost,
  currencyCode,
}: {
  shippingAddress: Maybe<IShippingAddress>
  nbrPlates: number
  totalCost: number
  currencyCode: TSubscriptionCurrencyCode
}): Maybe<TPlateStorePostBody> {
  if (
    !shippingAddress?.line1 ||
    !shippingAddress.zip ||
    !shippingAddress.country ||
    !shippingAddress.city ||
    !shippingAddress.phone ||
    !shippingAddress.first_name ||
    !shippingAddress.last_name
  ) {
    return null
  }

  const name = `${shippingAddress.first_name} ${shippingAddress.last_name}`
  const countryName =
    countryCodeToCountry(shippingAddress.country) ?? shippingAddress.country

  return {
    order_quantity: nbrPlates,
    address: {
      street_name1: shippingAddress.line1,
      street_name2: shippingAddress.line2,
      postal_code: shippingAddress.zip,
      country: countryName,
      city: shippingAddress.city,
      region: shippingAddress.state_code,
      company: shippingAddress.company,
    },
    name,
    phone_number: shippingAddress.phone,
    price: totalCost,
    currency: currencyCode,
  }
}
