import { useState } from "react"

import { MountingPlateOrderContext } from "src/components/order/MountingPlateStore/MountingPlateOrderContext"
import {
  extractShippingAddressData,
  getCurrencyFromShippingRegion,
  getPlateShippingRegion,
  getPlateUnitCost,
} from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/mountingPlateOrderUtil"
import { IShippingAddress } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/shippingAddressTypes"
import { useFetchBillingInfoWithCreate } from "src/data/billing/queries/billingQueries"
import { storedShippingAddress } from "src/data/storage/storage"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { formatPrice } from "src/utils/formatPrice"
import { Maybe } from "src/utils/tsUtil"

/** This context will keep track of the shipping address, and keep entered data
 * in sync with storage so that it may be reloaded even after the user leaves
 * the page. */
export function MountingPlateOrderProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [shippingAddress, setShippingAddress] = useState<
    Maybe<IShippingAddress>
  >(storedShippingAddress.get())

  const [nbrPlates, setNbrPlates] = useState<number>(1)

  const user = useGetUser()
  const fetchBillingInfoWithCreate = useFetchBillingInfoWithCreate({
    user,
    options: {
      onSuccess(data) {
        // If shippingAddress hasn't been set, fall back to using billing address
        if (!storedShippingAddress.get() && data.billing_address) {
          setShippingAddress(extractShippingAddressData(data.billing_address))
        }
      },
    },
  })

  function resetStorage() {
    storedShippingAddress.clear()
    fetchBillingInfoWithCreate.refetch() // will default shipping address to billing address
  }

  const shippingRegion = getPlateShippingRegion(
    shippingAddress?.country ?? "US"
  )
  const totalCost = getPlateUnitCost(shippingRegion) * nbrPlates
  const currencyCode = getCurrencyFromShippingRegion(shippingRegion)
  const formattedCost = formatPrice(totalCost, currencyCode)

  return (
    <MountingPlateOrderContext.Provider
      value={{
        nbrPlates,
        setNbrPlates,
        shippingAddress,
        setShippingAddress,
        shippingCountry: shippingAddress?.country ?? null,
        loading: fetchBillingInfoWithCreate.isInitialLoading,
        userBillingInfo: fetchBillingInfoWithCreate.data ?? null,
        currencyCode,
        totalCost,
        formattedCost,
        storeShippingAddress: () =>
          shippingAddress && storedShippingAddress.set(shippingAddress),
        resetStorage,
      }}
    >
      {children}
    </MountingPlateOrderContext.Provider>
  )
}
